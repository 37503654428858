import './src/scss/index.scss'

import React from 'react'
import { AppContextProvider } from './src/context/AppContext'
import { globalHistory } from '@reach/router'

export const wrapRootElement = ({ element }) => (
	<AppContextProvider>{element}</AppContextProvider>
)

/*
export const onClientEntry = () => {

}*/

export const onPreRouteUpdate = () => {

}

/*
const originalNavigate = globalHistory.navigate
globalHistory.navigate = (...props) => {
	// your logic ...
	originalNavigate(...props)
}*/
