import React, { createContext, useContext, useState } from 'react'

type Props = {
	children: React.ReactNode
}

export interface IContextData {
	interstitialModal: {
		isOpen: boolean
		anchorElement?: HTMLAnchorElement
		title?: string
		message?: string
		continueButtonText?: string
	}
}

type ContextDataType = {
	contextData: IContextData
	updateState: (contextData: IContextData) => void
}

const defaultState = {
	contextData: {
		interstitialModal: {
			isOpen: false,
		},
	},
}

const AppContext = createContext<ContextDataType | null>(null)

export const AppContextProvider = ({ children }: Props) => {
	const [contextData, setContextData] = useState<IContextData>(
		defaultState.contextData,
	)
	const updateState = (_contextData: IContextData) =>
		setContextData(_contextData)

	const contextValues: ContextDataType = {
		contextData,
		updateState,
	}
	return (
		<AppContext.Provider value={contextValues}>{children}</AppContext.Provider>
	)
}

export const useAppContext = () => {
	const context = useContext(AppContext)
	if (context === undefined || context === null) {
		throw new Error('useAppContext must be called within AppContextProvider')
	}
	return context
}
