exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-exkivity-tsx": () => import("./../../../src/pages/about-exkivity.tsx" /* webpackChunkName: "component---src-pages-about-exkivity-tsx" */),
  "component---src-pages-egfr-exon-20-tsx": () => import("./../../../src/pages/egfr-exon20.tsx" /* webpackChunkName: "component---src-pages-egfr-exon-20-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-possible-side-effects-tsx": () => import("./../../../src/pages/possible-side-effects.tsx" /* webpackChunkName: "component---src-pages-possible-side-effects-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-study-results-tsx": () => import("./../../../src/pages/study-results.tsx" /* webpackChunkName: "component---src-pages-study-results-tsx" */),
  "component---src-pages-takeda-oncology-here-2-assist-tsx": () => import("./../../../src/pages/takeda-oncology-here2assist.tsx" /* webpackChunkName: "component---src-pages-takeda-oncology-here-2-assist-tsx" */),
  "component---src-pages-taking-exkivity-tsx": () => import("./../../../src/pages/taking-exkivity.tsx" /* webpackChunkName: "component---src-pages-taking-exkivity-tsx" */)
}

